import './App.css';
import React, {useEffect} from 'react';  
import axios from 'axios';
import { useOutlet, Link } from 'react-router-dom';
import { Space } from 'antd';

// eslint-disable-next-line
import log from 'loglevel';

import { FacebookFilled, InstagramFilled, SpotifyFilled, TikTokFilled, YoutubeFilled } from '@ant-design/icons';
import { useWindowSizeContext } from './SettingContext';
import { imagePath } from './util';

function Webpage() {
    const outlet = useOutlet();
    const { width, height } = useWindowSizeContext();
    const [ main, setMain] = React.useState(undefined);

    useEffect(() => {
        document.title = 'Numento';
    });

    useEffect(() => {
        axios.get('/items/Main')
            .then(response => {
                setMain(response.data.data);
            })
            .catch(error => {
                console.error('There was an error!', error);
            });        
    }, []);

    if (height === 0 || width === 0 || main === undefined) {  
        return <div>Loading...</div>;
    }
      
    return (
        <div className="content"> 
            <header className="App-header">
                <div >
                    <Link to="/home">
                        <img className="App-logo" src={imagePath(main?.logo)} alt="Numento" />
                    </Link>
                </div>

                <div className="menu">
                    <span className="menuItems">
                        <Link to="/news"><span className="menuLink">News</span></Link>
                        <Link to="/discography"><span className="menuLink">Discography</span></Link>
                        <Link to="/band"><span className="menuLink">Band</span></Link>
                        <Link to="/gigs"><span className="menuLink">Gigs</span></Link>
                        <Link to="/contact"><span className="menuLink">Contact</span></Link>
                    </span>
                </div>
            </header>
            <div className="page">
                {outlet}
            </div>
            <div className='footer'>
                <Space size="large" className="icons">
                    <a href="https://www.facebook.com/numentofin" target="_blank" rel="noopener noreferrer">
                        <FacebookFilled size="20px"/>
                    </a>
                    <a href="https://instagram.com/numentoband" target="_blank" rel="noopener noreferrer">
                        <InstagramFilled/>
                    </a>
                    <a href="https://open.spotify.com/artist/3t5HzeySOZvzNSEpvDiA5M?si=NaWdxuZORp-IgENMSsmZcQ" target="_blank" rel="noopener noreferrer">
                        <SpotifyFilled/>
                    </a>
                    <a href="https://www.youtube.com/channel/UC_3YNrisPhQufU6IJmOEnMg" target="_blank" rel="noopener noreferrer">
                        <YoutubeFilled/>
                    </a>
                    <a href="https://tiktok.com/@numentoband" target="_blank" rel="noopener noreferrer">
                        <TikTokFilled/>
                    </a>            
                </Space>
                <span className="copyright">Copyright © Numento 2024</span>
            </div>
        </div>
    );
}

export default Webpage;
