import React, { createContext, useContext } from "react";
import { useWindowSize } from "usehooks-ts";

const WindowSizeContext = createContext();  

export function WindowSizeProvider({ children }) {
    const { width = 0, height = 0 } = useWindowSize();
    return (
        <WindowSizeContext.Provider value={{ width, height }}>
            {children}
        </WindowSizeContext.Provider>
    );
}

export function useWindowSizeContext() { 
  return useContext(WindowSizeContext);
}


