import './discography.css';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, Flex, Divider } from 'antd';

// eslint-disable-next-line
import log from 'loglevel';

import { imagePath } from '../util';
import { CustomScroll } from "react-custom-scroll";

const {Meta} = Card;

export function Discography() {
    const [discography, setDiscography] = useState([]);
    useEffect(() => {
        document.title = 'Numento - Discography';
    });

    useEffect(() => {
        axios.get('/items/discography')
            .then(response => {
                setDiscography(response.data.data);
            })
            .catch(error => {
                console.error('There was an error!', error);
            });        
    }, []);

    const fullLengthAlbums = discography.filter(item => item.type === "Full-length")
      .sort((a, b) => new Date(b.release_date) - new Date(a.release_date));

    const singles = discography.filter(item => item.type === "Single")
      .sort((a, b) => new Date(b.release_date) - new Date(a.release_date));


    return (
        <CustomScroll heightRelativeToParent="100%">
            <div className='discographyPage'>
                <Divider style={{margin: '10px 0'}}orientation='left'><h3>Albums</h3></Divider>
                <Flex wrap gap="middle" justify="space-evenly">
                    {fullLengthAlbums.map((album) => {
                        if (album?.Show !== true) {
                            return null;
                        }
                        return (
                            <Link key={album.id} to={`/discography/${album.id}`}>
                                <Card
                                    hoverable
                                    style={{ width: 300 }}
                                    cover={<img alt="example" src={imagePath(album.album_cover)} />}
                                >
                                    <Meta title={album?.album_title} description={`Release date: ${album?.release_date} `} />

                                </Card>
                            </Link>
                        );
                    })}
                </Flex>

                <Divider style={{margin: '10px 0'}} orientation='right'><h3>Singles</h3></Divider>
                <Flex wrap gap="middle" justify="space-evenly">
                    {singles.map((album) => {
                        if (album?.Show !== true) {
                            return null;
                        }
                        return (
                            <Link key={album.id} to={`/discography/${album.id}`}>
                                <Card
                                    hoverable
                                    style={{ width: 300 }}
                                    cover={<img alt="example" src={imagePath(album.album_cover)} />}
                                >
                                    <Meta title={album?.album_title} description={`Release date: ${album?.release_date} `} />

                                </Card>
                            </Link>
                        );
                    })}
                </Flex>

            </div>
        </CustomScroll>
    );
}
