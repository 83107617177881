import './contact.css';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Card, Flex } from 'antd';

// eslint-disable-next-line
import log from 'loglevel';

export function Contact() {
    const [contact, setContact] = useState([]);
    useEffect(() => {
        document.title = 'Numento - Contact';
    });

    useEffect(() => {
        axios.get('/items/contact')
            .then(response => {
                setContact(response.data.data);
            })
            .catch(error => {
                console.error('There was an error!', error);
            });        
    }, []);

    const createListItems = (data) => {
        const httpsUrlPattern = new RegExp('^https://', 'i');

        return data.split('\n').map((item, index) => {
            if (httpsUrlPattern.test(item)) {
                return <li key={index}><a href={item} target='_bland'>{item}</a></li>
            } else {
                return <li key={index}>{item}</li>
            }
        });
    }
        
    return (
        <div className='contactPage'>
          <Flex wrap gap="middle" justify="space-around">
            <Card title="Band emails" bordered={false} style={{width: 300}} >
              <ul>{createListItems(contact?.email || '')}</ul>
            </Card>
            <Card title="Booking information" bordered={false} style={{width: 300}} >
              <ul>{createListItems(contact?.booking || '')}</ul>
            </Card>
          </Flex>
        </div>
    );
}
