import './news.css';
import axios from "axios";
import React, {useEffect, useState} from "react"; 

// eslint-disable-next-line
import log from 'loglevel';
import { Divider } from 'antd';
import { CustomScroll } from 'react-custom-scroll';

export function News() {
    const [news, setNews] = useState([]);
    useEffect(() => {
        document.title = 'Numento - News';
    });

    useEffect(() => {
        axios.get('/items/news')
            .then(response => {
                setNews(response.data.data);
            })
            .catch(error => {
                console.error('There was an error!', error);
            });        
    }, []);

    const newsItems = news?.map((newsItem) => {
        return (        
            <div key={newsItem.id}>
                <p>{newsItem.date}</p>
                <h2>{newsItem.title}</h2>
                <div dangerouslySetInnerHTML={{ __html: newsItem.news_text }} />
                <Divider />
                
            </div>
        );
    });

    return (
        <CustomScroll heightRelativeToParent="100%">
            <div className="newsPage">        
                {newsItems}
            </div>
        </CustomScroll>
    );
}
