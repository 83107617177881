import './album.css';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
// eslint-disable-next-line
import log from 'loglevel';


import { imagePath } from '../util';
import { CustomScroll } from 'react-custom-scroll';

export function Album() {
    const albumId = useParams().albumId;
    const [album,setAlbum] = useState(undefined);


    useEffect(() => {
        document.title = 'Numento - ' + album?.album_title;
    });

    useEffect(() => {
        axios.get(`/items/discography/${albumId}`)
            .then(response => {
                setAlbum(response.data.data);
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    }, [albumId]);

    if (!album) {
        return <div>Loading...</div>;
    } 


    return (
      <CustomScroll heightRelativeToParent="100%">
        <div className="albumPage">
            <div className="albumLeft">
                <img src={imagePath(album?.album_cover)} alt={album?.album_title} />
                <h2>Release date: {album?.release_date}</h2>                

            </div>
            <div className='albumInfo'>   
                <h1>{album?.album_title}</h1>
                <h2>Tracklist</h2>
                <Markdown remarkPlugins={[remarkGfm]}>{album?.song_list}</Markdown>
                <h2>Lineup</h2>
                <Markdown remarkPlugins={[remarkGfm]}>{album?.line_up}</Markdown>
                <h2>Recording info</h2>
                <Markdown remarkPlugins={[remarkGfm]}>{album?.recording_info}</Markdown> 
                {album?.additional_info === null ? undefined : <h2>Additional notes</h2>}
                <Markdown remarkPlugins={[remarkGfm]}>{album?.additional_info}</Markdown> 

          </div>
        </div>
      </CustomScroll>
  );
}
