import './gigs.css';
import axios from "axios";
import React, {useEffect, useState} from "react";
import { Divider } from 'antd';
import { format } from 'date-fns';
import { FacebookFilled } from '@ant-design/icons';
import { CustomScroll } from 'react-custom-scroll';
// eslint-disable-next-line
import log from "loglevel";

export function Gigs() {

    const [gigs, setGigs] = useState([]);

    useEffect(() => {
        document.title = 'Numento - Gigs';
    });
    useEffect(() => {
        axios.get('/items/gigs')
            .then(response => {
                setGigs(response.data.data);
            })
            .catch(error => {
                console.error('There was an error!', error);
            });        
    }, []);

    const currentDate = new Date();

    const upcomingGigs = gigs.filter(gig => new Date(gig.date) >= currentDate)
        .sort((a, b) => new Date(a.date) - new Date(b.date));
        
    const pastGigs = gigs.filter(gig => new Date(gig.date) < currentDate)
        .sort((a, b) => new Date(b.date) - new Date(a.date));

    return (
        <CustomScroll heightRelativeToParent="100%">
            <div className="gigs-page">
              <div className="gigs-list">
                  <h2>Upcoming gigs</h2>
                  <div className="gigs-container">
                      {upcomingGigs.map((gig) => {
                          return (
                              <div>
                                  <div key={gig.id} className="gig-item">
                                      <div className='gig-details'>
                                          <p>{format(new Date(gig.date), 'EEEE, MMMM d, yyyy')}</p>
                                          <p className="gig-place">{gig.place}</p>
                                      </div>
                                      <div className='gig-links'>
                                          <p>{gig.city}</p>
                                          <p>{gig.country}</p>
                                          <div className="gig-buttons">
                                            {gig.ticket_url && <button>{<a href={gig.ticket_url}>Buy tickets</a>}</button>}
                                            {gig.facebook_url && <button>{<a href={gig.facebook_url}><FacebookFilled /></a>} </button>}
                                          </div>
                                      </div>
                                  </div>
                                  <Divider />
                          </div>
                          );
                      })}
                  </div>
                  <h2>Past gigs</h2>
                  <div className="gigs-container">
                    {pastGigs.map((gig) => {  
                        return (
                            <div>
                                <div key={gig.id} className="gig-item">
                                <div className='gig-details'>
                                    <p>{format(new Date(gig.date), 'EEEE, MMMM d, yyyy')}</p>
                                    <p>{gig.place}</p>
                                </div>
                                <div className='gig-links'>
                                    <p>{gig.city}</p>
                                    <p>{gig.country}</p>
                                    <div className="gig-buttons">
                                      {gig.facebook_url && <button>{<a href={gig.facebook_url}><FacebookFilled/></a>} </button>}
                                    </div>
                                </div>
                                </div>
                                <Divider />
                            </div>
                        );
                      })
                    }
                  </div>
              </div>
            </div>
        </CustomScroll>
    );
}
