import './band.css';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import axios from 'axios';
import { Space, Divider } from 'antd';
import { CustomScroll } from 'react-custom-scroll';

import { imagePath } from '../util';

// eslint-disable-next-line
import log from 'loglevel';

export function Band() {
    const [band, setBand] = useState([]);
    useEffect(() => {
        document.title = 'Numento - Band';
    });

    useEffect(() => {
        axios.get('/items/band')
            .then(response => {
                setBand(response.data.data);
            })
            .catch(error => {
                console.error('There was an error!', error);
            });        
    }, []);


    return (
        <CustomScroll heightRelativeToParent="100%">            
            <div className="bandPage">            
                <img src={imagePath(band?.band_photo)} alt="Numento" />
                <div>
                    <nav>
                        <Space direction="horizontal">
                            <Link to="/band/4"><h3>Mikko</h3></Link>
                            <Link to="/band/2"><h3>Atte</h3></Link>
                            <Link to="/band/1"><h3>Katri</h3></Link>
                            <Link to="/band/3"><h3>Aleksi</h3></Link>
                            <Link to="/band/5"><h3>Simo</h3></Link>
                        </Space>
                    </nav>
                </div>
                <div className='history'>
                    <Divider style={{margin: '10px 0'}}orientation='left'><h3>Numento history</h3></Divider>
                    <div className="bandInfo">
                        {band.history}
                    </div>
                </div>
            </div>
        </CustomScroll>
    );
}
